import React from "react"
import { Layout } from "../../components/Layout"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import { useStaticQuery, graphql } from "gatsby"
import HeaderMedia from "../../components/PageHeaderMedia"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { AiOutlineCaretRight } from "react-icons/ai"
import { IconContext } from "react-icons"
import { useLocationData } from "../../hooks/locationHook"

const Triangle = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "18px" }}>
    <AiOutlineCaretRight />
  </IconContext.Provider>
)

const StyledDiv = styled.div`
  padding: 50px 5%;
  display: flex;
  flex-flow: row nowrap;
  .imgArea {
    width: 30%;
    height: 300px;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .textArea {
    width: 70%;
    padding: 0 3%;
    h3 {
      font-size: 1.32em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 1.32em;
      &:before {
        margin-right: 0.32em;
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        border-radius: 5px;
        background: ${({ theme }) => theme.colors.primary.red};
      }
    }
  }
  .childrenText {
    padding: 10px 0;
    &:after {
      display: block;
      width: 80%;
      margin: 30px 10%;
      content: "";
      background: #dadada;
      height: 1px;
    }
    h4 {
      font-weight: bold;
      margin-bottom: 0.62em;
    }
    p {
      margin-bottom: 1.62em;
      line-height: 1.32em;
      font-size: 0.9em;
    }
  }
  .leadArea {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    p {
      font-size: 0.9em;
      line-height: 1.32em;
      margin-bottom: 30px;
    }
    a {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 0 0.62em;
      font-size: 0.8em;
      color: grey;
      overflow: hidden;
      &:after {
        transition: 0.6s;
        margin-top: 0.2em;
        display: block;
        content: "";
        width: 100%;
        height: 1px;
        background: red;
        transform: translateX(-120%);
      }
      svg {
        margin-right: 0.32em;
      }
      &:hover {
        &:after {
          transform: translateX(0);
        }
      }
    }
  }
  .textAreaGreen {
    h3 {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      &:before {
        background: ${({ theme }) => theme.colors.primary.green};
      }
    }
  }
  ${() =>
    media.sp(css`
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 30px 5% 15px !important;
      .imgArea {
        width: 100%;
        height: 100px;
        order: 2;
        margin-top: 1.62em;
      }
      .textArea {
        width: 100%;
        order: 1;
      }
    `)}
`

const DepartmentIntroduction = ({ location }) => {
  const { strapiDepartmentIntroduction: data } = useStaticQuery(query)
  const departmentList = data.departments.filter(data => data.id != 1)
  useLocationData(location.pathname)

  return (
    <Layout>
      <HeaderMedia
        fluid={data?.headerMediaPhoto?.childImageSharp.fluid}
        pageTitles={data?.headerMedia}
      />

      {departmentList.map((depData, index) =>
        index % 2 === 0 ? (
          <StyledDiv>
            <div className="imgArea">
              <Image fluid={depData.departmentMedia.childImageSharp.fluid} />
            </div>

            <div className="textArea">
              <h3>{depData.departmentTitle}</h3>
              {depData.departmentChildren.map(d => (
                <div className="childrenText">
                  <h4>{d.childrenTitle && d.childrenTitle}</h4>
                  {d.childrenContents && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: d.childrenContents,
                      }}
                    />
                  )}
                </div>
              ))}

              <div className="leadArea">
                {depData.departmentLead && (
                  <p
                    dangerouslySetInnerHTML={{ __html: depData.departmentLead }}
                  />
                )}
                {depData.departmentLinkUrl && (
                  <Link to={depData.departmentLinkUrl}>
                    <Triangle />
                    {depData.departmentLinkName && depData.departmentLinkName}
                  </Link>
                )}
              </div>
            </div>
          </StyledDiv>
        ) : (
          <StyledDiv>
            <div className="textArea textAreaGreen">
              <h3>{depData.departmentTitle}</h3>
              {depData.departmentChildren.map(d => (
                <div className="childrenText">
                  <h4>{d.childrenTitle && d.childrenTitle}</h4>
                  {d.childrenContents && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: d.childrenContents,
                      }}
                    />
                  )}
                </div>
              ))}

              <div className="leadArea">
                {depData.departmentLead && (
                  <p
                    dangerouslySetInnerHTML={{ __html: depData.departmentLead }}
                  />
                )}
                {depData.departmentLinkUrl && (
                  <Link to={depData.departmentLinkUrl}>
                    <Triangle />
                    {depData.departmentLinkName && depData.departmentLinkName}
                  </Link>
                )}
              </div>
            </div>

            <div className="imgArea">
              <Image fluid={depData.departmentMedia.childImageSharp.fluid} />
            </div>
          </StyledDiv>
        )
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    strapiDepartmentIntroduction {
      headerMedia
      headerMediaAlt
      headerMediaPhoto {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      departments {
        id
        departmentTitle
        departmentMediaAlt
        departmentMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        departmentLead
        departmentLinkName
        departmentLinkUrl
        departmentChildren {
          id
          childrenTitle
          childrenContents
        }
      }
    }
  }
`

export default DepartmentIntroduction
